import * as Sentry from '@sentry/browser';

const { VITE_SENTRY_DSN, VITE_RAILS_ENV } = import.meta.env;

if (VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: VITE_SENTRY_DSN,
    environment: VITE_RAILS_ENV,
    ignoreErrors: ['api.short.cm'],
  });
}
